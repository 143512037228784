import moment from 'moment';
import JWT from 'jsonwebtoken';
import React, { useEffect, useState } from 'react';
import { AccountInfo, EMPTY_ACCOUNT_INFO, LOADING_ACCOUNT_INFO } from '../context/AccountContext';
import {
    AlpsErrorResponse,
    AlpsRequestOption,
    AlpsSessionData,
    AlpsUserData,
    AuthenticationState,
    JwtClaims,
} from '../utils/types';
import { getAccessToken } from '../api/nintendoAccountApi';
import AppConfig from '../components/config/AppConfig';
import { useAuthenticationSessionTimeout } from '../hooks/authenticationHooks';

// Alps typescript setup
declare global {
    interface Window {
        Alps: {
            Api: {
                addGlobalEventHandler(
                    eventName: string,
                    callback: (
                        error: AlpsErrorResponse | null,
                        result: { response: AlpsSessionData } | null,
                    ) => void,
                ): void;
                requestSessionData(
                    callback?: ((data: AlpsSessionData | null) => void) | null,
                    options?: AlpsRequestOption,
                ): Promise<AlpsSessionData>;
                retrieveUserData(
                    callback?:
                        | ((
                              error: AlpsErrorResponse | null,
                              result: AlpsUserData | null,
                              isChangedNNAId: boolean,
                              isChangedLanguage: boolean,
                          ) => void)
                        | null,
                    options?: AlpsRequestOption,
                ): Promise<{ userData: AlpsUserData }>;
                showLogin(): ((data: AlpsSessionData | null) => void) | null;
            };
            mountRiot: {
                call(): void;
            };
        };
        alpsClientInfo: {
            clientId: string | undefined;
            scope: Array<String> | string;
        };
    }

    namespace JSX {
        interface IntrinsicElements {
            'alps-account-nav': any;
        }
    }
}

/**
 * A function to trade a Nintendo Account authorization code for a GP JWT token
 * @param data {AlpsSessionData | null} - The Alps session data
 * @param setAccountInfo {React.Dispatch<React.SetStateAction<AccountInfo>>} - Set state action for the account info
 * @param setAccessTokenExpiration {React.Dispatch<React.SetStateAction<number>>} - Set state action for access token expiration
 * @param setAuthServiceError {React.Dispatch<React.SetStateAction<boolean>>} - Set state action for indicating an auth service error
 */
const getToken = async (
    data: AlpsSessionData | null,
    setAccountInfo: React.Dispatch<React.SetStateAction<AccountInfo>>,
    setAccessTokenExpiration: React.Dispatch<React.SetStateAction<number>>,
    setAuthServiceError: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    if (data) {
        // Indicate that we are authenticating and loading the token
        setAccountInfo({
            ...data,
            token: '',
            loginCheck: true,
            birthday: '',
            participantId: '',
            userImage: '',
            showModal: false,
        });

        // Use the code we got from Alps to trade for a GP JWT
        const token = await getAccessToken(data.code);

        // Obtain the expiration and user's name from the signed JWT
        const result = JWT.decode(token) as JwtClaims;

        if (token) {
            if (result) {
                // Set our token expiration to 90% of the actual token's expiration so that we ensure it's refreshed on time
                const expirationDelay = moment(result.exp * 1000).diff(moment()) * 0.9;

                if (expirationDelay > 0) {
                    setAccessTokenExpiration(expirationDelay);
                }

                window.Alps.Api.retrieveUserData(async (error, userData) => {
                    if (error || userData == null) {
                        setAuthServiceError(true);
                    } else {
                        const birthday = userData.getBirthday() as string;

                        setAccountInfo({
                            ...data,
                            token,
                            loginCheck: false,
                            nickname: result.name,
                            birthday,
                            participantId: result.sub,
                            userImage: result.user_image,
                            showModal: false,
                        });
                    }
                });
            }
        } else {
            setAuthServiceError(true);
        }
    }
};

/**
 * A hook that sets up Alps/Nintendo Account authentication for the application. It will defer to the alps plugin to get
 * an auth code for the user, which is then traded with GPS to get a GP JWT. A timeout is set to refresh the access token
 * before it expires.
 *
 * @returns {AuthenticationState} Returns the state representing the authentication, which includes the account info,
 * a method to update the account info, an error indicator, authenticating status, and the user's nav bar element
 */
export const useAuthentication = (): AuthenticationState => {
    // Setup state
    const [accountInfo, setAccountInfo] = useState<AccountInfo>(LOADING_ACCOUNT_INFO);
    const [accessTokenExpiration, setAccessTokenExpiration] = useState<number>(0);
    const [authServiceError, setAuthServiceError] = useState<boolean>(false);

    useEffect(() => {
        window.Alps.Api.addGlobalEventHandler('login', async (error) => {
            // If error, set AccountInfo to empty
            if (error && error.errorCode) {
                setAccountInfo(EMPTY_ACCOUNT_INFO);
            }
            window.Alps.Api.requestSessionData(
                (data) =>
                    getToken(data, setAccountInfo, setAccessTokenExpiration, setAuthServiceError),
                { force: true },
            );
        });
    }, []);

    useAuthenticationSessionTimeout(accessTokenExpiration, () => {
        window.Alps.Api.requestSessionData(
            (data) => getToken(data, setAccountInfo, setAccessTokenExpiration, setAuthServiceError),
            {
                force: true,
            },
        );
    });

    return {
        authenticating: accountInfo.loginCheck,
        accountInfo,
        setAccountInfo,
        authServiceError,
        navBarUser: <alps-account-nav />,
    };
};

/**
 * The render setup function. For Alps we need to pick the correct Alps script for the provided locale before we can
 * render the application.
 * @param locale {string} - The user's locale
 * @param renderApp - A function that renders the application
 */
export const renderSetup = (locale: string, renderApp: () => void) => {
    window.alpsClientInfo = {
        clientId: AppConfig.nintendoAccountClientId,
        scope: [
            'openid',
            'userinfo.profile',
            'userinfo.birthday',
            'userinfo.mii',
            'pointWallet',
            'userNotificationMessage:anyClients',
        ],
    };

    // Create the Alps script tag. When it's loaded we can render our app.
    const script = document.createElement('script');
    script.src =
        locale && locale in AppConfig.alpsJs ? AppConfig.alpsJs[locale] : AppConfig.alpsJs['en-US'];
    script.onload = renderApp;
    document.head.appendChild(script);
};

/**
 * A function that defines how to prompt the user to login. For Alps this is a call to their showLogin() function.
 */
export const showLogin = () => {
    window.Alps.Api.showLogin();
};

/**
 * A function defining how to render the QR Code given a user's account info. For a Nintendo Account authenticated user
 * it's just their User ID.
 * @param accountInfo {AccountInfo} - The user's account info
 */
export const getQrCodeValue = (accountInfo: AccountInfo) => accountInfo.userId;

export const getQrCodeValueForUserId = (userId: string) => userId;
